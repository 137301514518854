<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableOneSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            New Booking
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableOneSettings.perPage"
        :current-page="tableOneSettings.currentPage"
        :items="myProvider"
        :fields="tableOneSettings.fields"
        :sort-by.sync="tableOneSettings.sortBy"
        :sort-desc.sync="tableOneSettings.sortDesc"
        :sort-direction="tableOneSettings.sortDirection"
        :filter="tableOneSettings.filter"
        :filter-included-fields="tableOneSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableOneSettings.currentPage * tableOneSettings.perPage - tableOneSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              @click="onViewDetails(row.item)"
            >
              View
            </b-button>
            <b-button
              v-if="['pending', 'reserved'].includes(row.item.status)"
              size="sm"
              variant="danger"
              @click="onCancelAction(row.item)"
            >
              Cancel
            </b-button>
          </div>

        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableOneSettings.perPage"
              :options="tableOneSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableOneSettings.currentPage"
            :total-rows="tableOneSettings.totalRows"
            :per-page="tableOneSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-booking-form"
      size="lg"
      scrollable
      no-close-on-backdrop
      title="New Booking"
      @ok="onConfirmAction"
    >
      <ValidationObserver
        ref="form"
      >
        <form
          role="form"
          novalidate
        >

          <ValidationProvider
            #default="{ errors }"
            name="venue"
            vid="venue"
            rules="required"
          >
            <b-form-group>
              <label for="venue">Venue</label>
              <b-form-select
                id="venue"
                v-model="booking.venue"
                :options="venues"
                :state="errors.length > 0 ? false : null"
                :disabled="(state.busy || booking.details.length > 0)"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Venue --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="date from"
            vid="date_from"
            rules="required"
          >
            <b-form-group>
              <label for="date_from">Date From</label>
              <b-input
                id="date_from"
                v-model="booking.date_from"
                type="datetime-local"
                :state="errors.length > 0 ? false : null"
                placeholder="enter date from"
                autocomplete="off"
                :min="minDateFrom"
                :disabled="(state.busy || booking.details.length > 0 || booking.venue === null)"
                @change="onDateFromChange"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="date to"
            vid="date_to"
            rules="required"
          >
            <b-form-group>
              <label for="date_to">Date To</label>
              <b-input
                id="date_to"
                v-model="booking.date_to"
                type="datetime-local"
                :state="errors.length > 0 ? false : null"
                placeholder="enter date to"
                autocomplete="off"
                :min="minDateTo"
                :disabled="(state.busy || booking.details.length > 0 || booking.venue === null)"
                @change="getItemList"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="purpose"
            vid="purpose"
            rules="required|max:500"
          >
            <b-form-group>
              <label for="purpose">Purpose</label>
              <b-textarea
                id="purpose"
                v-model="booking.purpose"
                :state="errors.length > 0 ? false : null"
                placeholder="enter purpose"
                autocomplete="off"
                rows="6"
                max-rows="6"
                :disabled="(state.busy || booking.venue === null)"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </b-form-group>
          </ValidationProvider>

          <div>
            <ValidationProvider
              #default="{ errors }"
              name="item"
              vid="item"
              rules=""
            >
              <b-form-group>
                <label for="item">Available Item(s)</label>
                <v-select
                  id="item"
                  v-model="selectedItem"
                  label="name"
                  :options="bookingItemFilteredLists"
                  :state="errors.length > 0 ? false : null"
                  :disabled="(state.busy || booking.venue === null)"
                >
                  <template #first>
                    <v-select-option
                      :value="null"
                      disabled
                    >
                      -- Please Select Item --
                    </v-select-option>
                  </template>
                </v-select>
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
            <b-button
              :disabled="(allowAddItem || selectedItem === null)"
              @click="onAddItem"
            >
              Add Item
            </b-button>
            <hr>
            <b-table
              hover
              responsive
              class="mt-2"
              :items="booking.details"
              :fields="tableTwoSettings.fields"
              show-empty
            >
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell()="data">
                <div class="text-nowrap">
                  {{ data.value }}
                </div>
              </template>

              <template #cell(actions)="row">
                <b-button
                  size="sm"
                  @click="onRemoveItem(row.item)"
                >
                  remove
                </b-button>
              </template>

            </b-table>

          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="(state.busy || booking.details.length < 1)"
          @click="ok()"
        >
          Submit
        </b-button>

        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>

    </b-modal>

    <b-modal
      id="modal-view-details"
      size="lg"
      scrollable
      no-close-on-backdrop
      title="View Details"
    >
      <div>
        <b-table
          hover
          responsive
          class="mt-2"
          :items="booking.details"
          :fields="tableThreeSettings.fields"
          show-empty
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell()="data">
            <div class="text-nowrap">
              {{ data.value }}
            </div>
          </template>
        </b-table>
      </div>

      <template #modal-footer="{cancel}">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, UserBookingService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'UserMyBookings',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'My Bookings'
    }
  },

  mixins: [formatter],

  data () {
    return {
      date_now: this.dateTimeLocalFormatter(Date.now()),
      state: {
        busy: false
      },
      selectedItem: null,
      items: [],
      venues: [],
      booking: {
        id: null,
        venue: null,
        date_from: this.dateTimeLocalFormatter(Date.now()),
        date_to: this.dateTimeLocalFormatter(Date.now()),
        purpose: '',
        status: '',
        details: []
      },
      tableOneSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'status' },
          { key: 'created_at', label: 'booked at', formatter: this.dateTimeFormatter, sortKey: 'bookings.created_at', sortable: true },
          { key: 'venue' },
          { key: 'date_from' },
          { key: 'date_to' }
          // { key: 'purpose' }
        ]
      },
      tableTwoSettings: {
        fields: [
          'index',
          'item_name',
          'actions'
        ]
      },
      tableThreeSettings: {
        fields: [
          'index',
          'item_name',
          { key: 'date_from', formatter: this.dateTimeFormatter },
          { key: 'date_to', formatter: this.dateTimeFormatter }
        ]
      }
    }
  },

  computed: {
    allowAddItem () {
      const isOk = !!(this.selectedItem?.id !== null && this.booking.date_from < this.booking.date_to)
      return !isOk
    },

    minDateFrom () {
      return this.dateTimeLocalFormatter(this.date_now)
    },

    minDateTo () {
      return this.dateTimeLocalFormatter(this.booking.date_from)
    },

    bookingItemFilteredLists () {
      return this.items.filter(item => !this.booking.details.map(detail => detail.item_name).includes(item.name))
    }
  },

  mounted () {
    core.index()
    this.getVenueList()
  },

  methods: {

    async myProvider (ctx) {
      try {
        const { data } = await UserBookingService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}`)
        this.tableOneSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getVenueList () {
      await SharedListService.getVenueList().then(({ data }) => {
        data.forEach(item => {
          this.venues.push({
            value: item.id,
            text: item.name,
            label: item.name
          })
        })
      })
    },

    async getItemList () {
      this.selectedItem = null
      if (this.booking.date_from > this.booking.date_to) {
        this.$refs.form.setErrors({
          date_to: ['The date to must be a date after date from']
        })
      } else {
        this.state.busy = true
        await SharedListService.getItemList(`date_from=${this.booking.date_from}&date_to=${this.booking.date_to}`).then(({ data }) => {
          this.items = data
          this.state.busy = false
        }).catch(() => {
          this.state.busy = false
        })
      }
    },

    onDateFromChange () {
      if (this.booking.date_from < this.dateTimeLocalFormatter(Date.now())) {
        this.$refs.form.setErrors({
          date_from: ['The date from must be a date after or equal to now']
        })
      } else {
        if (this.booking.date_from >= this.booking.date_to) {
          this.booking.date_to = null
        } else {
          this.getItemList()
        }
      }
    },

    onShowAddForm () {
      this.resetForm()
      this.$bvModal.show('modal-booking-form')
    },

    onViewDetails (item) {
      this.booking.details = item.booking_details
      this.$bvModal.show('modal-view-details')
    },

    onAddItem () {
      if (this.selectedItem.item_id === null) {
        this.$bvModal.msgBoxOk('Oops! You have selected invalid item', {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          centered: true
        })
      } else if (this.booking.date_from >= this.booking.date_to) {
        this.$bvModal.msgBoxOk('Oops! Invalid booking date.', {
          title: 'Error',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          centered: true
        })
      } else {
        const isExisting = this.booking.details?.filter(
          el => el.item_id === this.selectedItem.id
        )

        if (isExisting.length) {
          this.$bvModal.msgBoxOk(`Item ${this.selectedItem.name} already exists in your item list.`, {
            title: 'Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        } else {
          this.booking.details.push({
            item_id: this.selectedItem.id,
            item_name: this.selectedItem.name
          })
          this.selectedItem = null
        }
      }
    },

    onRemoveItem (item) {
      const oldItem = this.booking.details

      const newItem = oldItem.filter(el => {
        return el.item_name !== item.item_name
      })

      this.booking.details = newItem
    },

    resetForm () {
      this.booking.id = null
      this.booking.venue = null
      this.booking.purpose = ''
      this.booking.date_from = this.dateTimeLocalFormatter(Date.now())
      this.booking.date_to = this.dateTimeLocalFormatter(Date.now())
      this.booking.details = []
      this.booking.status = ''
      this.items = []
      this.selectedItem = null
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.booking.details.length < 1) {
        return
      }

      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to submit booking?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk(
            'Oops! There were problem with your inputs.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            }
          )
        }
      })
    },

    async onCancelAction (booking) {
      this.booking.id = booking.id
      this.booking.status = 'cancelled'

      this.$swal.fire({
        icon: 'question',
        title: 'Question',
        text: 'Do you really want to cancel this request?',
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Yes',
        cancelButtonColor: '#FF2929',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.onFormCancelSubmit()
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then(action => {
        if (action.isDismissed) {
          this.resetForm()
        }
      })
    },

    async onFormPostSubmit () {
      this.state.busy = true
      return new Promise(resolve => {
        UserBookingService.post(this.booking).then(({ data }) => {
          this.$bvModal.hide('modal-booking-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              resolve(error)
            }
          }
        }).finally(() => {
          this.state.busy = false
        })
      })
    },

    async onFormCancelSubmit () {
      this.state.busy = true
      return new Promise(resolve => {
        UserBookingService.put(this.booking).then(({ data }) => {
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$swal.fire({
                icon: 'warning',
                title: 'Warning',
                text: error.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                resolve(error)
              })
            }
          }
        }).finally(() => {
          this.state.busy = false
        })
      })
    }

  }
}
</script>
